<template>
  <div>
    <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn  label="顾客姓名" prop="customer_name" />
            <ElTableColumn  label="手机号" prop="telephone" />
            <ElTableColumn label="门店" prop="shop_name" />
			      <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="疤痕类型" prop="scar_name" />
            <ElTableColumn  label="客户分类" prop="member_classification"/>
			      <ElTableColumn label="累计消费金额" prop="consumption" />
            <ElTableColumn  label="最新消费时间" prop="final_consumption"/>
            
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
          
        </el-col>
      </el-row>
  </div>
</template>
<script>

import pagination from '@/components/Pagination'
import { getYBVipList } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: 'ConsumeDetail',
   components: {
    pagination
  },
  data () {
    return {
      list: [], // 数据展示
      page: new Page(), // 分页
      type:''
    }
  },
  mounted () {
    // this.type = this.$route.query.type
    console.log(this.$route.query)
    this.getList();
  },
  methods: {
    getList() {
      getYBVipList({ ...this.page, ...this.$route.query}).then(res => {
        this.list = res.list;
        // // this.list.forEach((val) => {
        // //   val['shop_name'] = res.data.shop_name;
        // // });
        this.page.total = res.dataCount;
      });
    },

  }
}

</script>

<style lang="scss" scoped>
.reveal{
  text-align: right;
  padding: 20px 0;
  .peril{
    font-weight: bold;
  }
}
.btnBox{
  text-align: right;
}
</style>
